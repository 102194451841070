import React, { useState, useEffect } from 'react';
import Backend from './App';


function FnnBackend() {
    return (
      <div style={{color: 'white'}}> 
      <Backend></Backend>
      </div>
    );
  }
  
  export default FnnBackend;
  