import React, { useState, useRef, useEffect } from 'react';
import '../css/Report.css'; // Import the custom CSS file
import '../css/BindList.css'; // Import the custom CSS file
import {
  getColumnClassName,
  getColumnColor,
  getHeaderColor,
} from './ReportStyle.js';
import '../css/FilesTable.css'; // Import the custom CSS file

// Define the structure of the object in the detector data
interface DetectorObject {
  mac: string;
  ip: string;
  last_active: string;
  online: boolean;
  status: {
    site_name: string;
    gps_fix: boolean;
    gps_lat: number;
    gps_long: number;
    up_since: string;
    memory: string;
    fw_version: string;
    test_mode: boolean;
    thr: string;
    linux: boolean;
  };
}

interface ReportComponentProps {
  detectorData: DetectorObject[];
  reportGenAt: string;
  preferredColumnsOrder: any;
  expandedRow: any;
  setExpandedRow: any; // Expecting a ref here
}
const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

const getColorForMemory = (count: number, isDetectorOn: boolean) => {
  if (!isDetectorOn) return
  if (count > 15) return "red"; //red
  if (count > 0.5 && count <= 15) return "#d5b60a"; //yellow
  if (count <= 0.5) return;
};

const getColorForGPSFix = (isFixed: boolean, isDetectorOn: boolean) => {
  if (!isDetectorOn) return
  if (isFixed === false) return "red"; //red
  if (isFixed === true) return;
};

const calculateTimeAgo = (lastActive: string): string => {
  // Parse the string into a Date object in UTC
  const lastActiveDate = new Date(lastActive + 'Z'); // Ensure it's treated as UTC

  // Get the current date and time in UTC
  const now = new Date(Date.now()); // Get the current date and time
  // Calculate the difference in seconds
  const differenceInSeconds = Math.floor(
    (now.getTime() - lastActiveDate.getTime()) / 1000
  );

  // Calculate time in different units
  const minutes = Math.floor(differenceInSeconds / 60);
  const hours = Math.floor(differenceInSeconds / 3600);
  const days = Math.floor(differenceInSeconds / 86400);

  // Format the output
  if (differenceInSeconds < 60) {
    return `${differenceInSeconds} second${differenceInSeconds !== 1 ? 's' : ''} ago`;
  } else if (minutes < 60) {
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else {
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  }
};

const ReportComponent: React.FC<ReportComponentProps> = ({ detectorData, reportGenAt, preferredColumnsOrder, expandedRow, setExpandedRow }) => {

  const ObjectTable: React.FC<{ data: DetectorObject[]; expandedRow: any; setExpandedRow: any }> = ({ data, expandedRow, setExpandedRow }) => {

    // Convert the object to an array of entries
    const entries = Object.entries(data);

    // Group the data based on site_name
    //const groupedData = groupDataByColumn(data, "site_name");

    const toggleRow = (mac: string) => {
      if (expandedRow === mac) {
        setExpandedRow(null);
      } else {
        setExpandedRow(mac);
      }
    };

    return (
      <div className="table-responsive-xl m-4">
        <table className="backend table table-hover table-dark">
          <thead className='table-header'>
            <tr>
              <th>Site Name</th>
              <th>MAC</th>
              <th>IP</th>
              <th>Last Active</th>
              <th>Online</th>
              <th>Memory</th>
              <th>GPS Fix</th>
            </tr>
          </thead>
          <tbody className={"expanded-table-whole"}>
            {entries.map(([key, obj]) => (
              <React.Fragment key={key}>
                <tr
                  onClick={() => toggleRow(obj.mac)}
                  style={{ cursor: 'pointer' }}
                  className={getColumnColor(obj.online)}
                >
                  <td>{obj.status.site_name}</td>
                  <td>{obj.mac}</td>
                  <td>{obj.ip}</td>
                  <td>{calculateTimeAgo(obj.last_active)}</td>
                  <td>{obj.online ? 'Yes' : 'No'}</td>
                  <td><p style={{ color: getColorForMemory(Number(obj.status.memory), obj.online), display: "inline", margin: 0 }}>
                    {obj.status.memory}
                  </p></td>
                  <td><p style={{ color: getColorForGPSFix(obj.status.gps_fix, obj.online), display: "inline", margin: 0 }}>
                    {obj.status.gps_fix === true ? "Yes" : "No"}
                  </p></td>
                </tr>
                {expandedRow === obj.mac && (
                  <tr>
                    <td colSpan={7}>
                      <div className="p-2">
                        <strong>Status Details:</strong>
                        <table className="backend table table-hover table-dark">
                          <tbody className={"expanded-table"}>
                            <tr className={"expanded-table"}>
                              <td>GPS Fix</td>
                              <td><p style={{ color: getColorForGPSFix(obj.status.gps_fix, obj.online), display: "inline", margin: 0 }}>
                                {obj.status.gps_fix === true ? "Yes" : "No"}
                              </p></td>
                            </tr>
                            <tr className={"expanded-table"}>
                              <td>GPS Latitude</td>
                              <td>{obj.status.gps_lat}</td>
                            </tr>
                            <tr className={"expanded-table"}>
                              <td>GPS Longitude</td>
                              <td>{obj.status.gps_long}</td>
                            </tr>
                            <tr className={"expanded-table"}>
                              <td>Last Seen</td>
                              <td>{formatDate(obj.last_active)} UTC</td>
                            </tr>
                            <tr className={"expanded-table"}>
                              <td>Up Since</td>
                              <td>{obj.status.up_since}</td>
                            </tr>
                            <tr className={"expanded-table"}>
                              <td>Site Name</td>
                              <td>{obj.status.site_name}</td>
                            </tr>
                            <tr className={"expanded-table"}>
                              <td>Memory</td>
                              <td><p style={{ color: getColorForMemory(Number(obj.status.memory), obj.online), display: "inline", margin: 0 }}>
                                {obj.status.memory}
                              </p></td>
                            </tr>
                            <tr className={"expanded-table"}>
                              <td>Firmware Version</td>
                              <td>{obj.status.fw_version}</td>
                            </tr>
                            <tr className={"expanded-table"}>
                              <td>Test Mode</td>
                              <td>{obj.status.test_mode ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr className={"expanded-table"}>
                              <td>Threshold</td>
                              <td>{obj.status.thr}</td>
                            </tr>
                            <tr className={"expanded-table"}>
                              <td>Linux</td>
                              <td>{obj.status.linux ? 'Yes' : 'No'}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Example usage
  return (
    <>
      {reportGenAt && (
        <h3 className={getHeaderColor(reportGenAt)}>
          Generated at: {formatDate(reportGenAt)}
        </h3>
      )}

      <ObjectTable data={detectorData} expandedRow={expandedRow} setExpandedRow={setExpandedRow} />
      {/* <button className="bnt-download">
        Download
      </button> */}
    </>
  );
};

export default ReportComponent;
